<template>
  <div class="related-products">
    <div class="related-products__select">
      <v-select
        v-model="selected"
        outlined
        label="Choisir la gamme de fourniture"
        :items="productsList"
        item-text="name"
        item-value="product_id"
        persistent-placeholder
        dense
        hide-details
        return-object
        @change="onChange"
        :error="
          (customProductError && selected.service_product_id === 'default') ||
          selected.service_product_id === 'empty'
        "
      ></v-select>
    </div>
    <ProductInfo
      v-if="
        selected.service_product_id !== 'default' &&
        selected.service_product_id !== 'empty' &&
        productInfo
      "
      :loader="loader"
      :image="styleItem(productInfo.image)"
      :description="productInfo.description || ''"
      :pricePerUnit="
        amount
          ? currentProductPriceWithTaxByUnit
          : pricePerUnit !== null
          ? pricePerUnit
          : 0
      "
      :priceTotal="currentProductPriceWithTaxByUnit * amount"
      :serviceUnit="serviceUnit"
    />
    <CustomProductInfo
      v-if="selected.service_product_id === 'default' && customProduct"
      :loader="loader"
      :name="customProduct.name"
      :mark="customProduct.mark"
      :reference="customProduct.reference || ''"
      :description="customProduct.description || ''"
      :customPrice="(customProduct.client_price || 0).toFixed(2) + ' €'"
    />
    <div class="related-products__button">
      <v-btn
        v-if="selected.service_product_id === 'default'"
        outlined
        :color="!customProductError ? 'primary' : 'error'"
        depressed
        x-large
        @click="showModal = true"
      >
        <template v-if="!customProduct">
          <v-icon dark class="mx-2"> mdi-plus-circle-outline </v-icon>
          Informations sur mon article
        </template>
        <template v-if="customProduct">
          <v-icon dark class="mx-2"> mdi-pencil </v-icon>
          Editer mon article
        </template>
      </v-btn>
      <div
        v-if="selected.service_product_id === 'default' && customProductError"
        class="error--text text-center"
      >
        <small>Requis</small>
      </div>
    </div>

    <ArticleOfProductModal
      :show="showModal"
      :model="customProduct"
      @updateCustomProduct="$emit('updateCustomProduct')"
      @closeModal="showModal = false"
      @actionCustomProduct="actionCustomProduct"
    />
  </div>
</template>

<script>
import { pathImage } from "@/utils/index.js";
import {
  ProductsService,
  ServiceProductsService,
  ServiceProductPriceService,
} from "@/services/product.service.js";

export default {
  name: "ProductsOfService",
  props: {
    relatedProducts: {
      type: Array,
      required: true,
    },
    amount: {
      type: Number,
    },
    roomProduct: {
      type: Object,
    },
    serviceUnit: {
      type: String,
      required: true,
    },
    serviceTax: {
      type: Number,
      required: true,
    },
    customProductFormData: {
      type: Object,
    },
    currentProductPriceWithTaxByUnit: {
      type: Number,
      default: () => 0
    }
  },
  components: {
    ArticleOfProductModal: () =>
      import("../ArticleOfProductModal/ArticleOfProductModal"),
    ProductInfo: () => import("./ProductInfo.vue"),
    CustomProductInfo: () => import("./CustomProductInfo.vue"),
  },
  data() {
    return {
      loader: false,
      loaderModal: false,
      showModal: false,
      selected: {
        service_product_id: "empty",
        name: "Sélectionnez la fourniture",
      },
      productInfo: null,
      prices: [],
      pricesRange: [],
      pricePerUnit: 0,
      priceTotal: 0,
    };
  },
  computed: {
    price() {
      let price = this.prices?.length > 0 ? this.prices[0].price : 0;
      let priceRange =
        this.pricesRange.find(
          (range) =>
            range.amount_max >= this.amount && range.amount_min <= this.amount
        )?.price || null;
      return priceRange ? priceRange : price;
    },
    productsList() {
      let products = [
        {
          service_product_id: "empty",
          name: "Sélectionnez la fourniture",
        },
        {
          service_product_id: "default",
          name: "Mon propre article",
        },
      ];
      products.push(
        ...this.relatedProducts?.map((product) => {
          return {
            ...product,
            name: product?.name || product.product_id,
          };
        })
      );
      return products;
    },
    styleItem() {
      return (path) => {
        if (!path) return null;
        let pathUrl = pathImage(path);
        return pathUrl ? { backgroundImage: `url(${pathUrl})` } : null;
      };
    },
    customProduct() {
      if (this.customProductFormData?.product) {
        return this.customProductFormData.product;
      } else if (this.roomProduct?.custom_product_id) {
        return this.roomProduct?.custom_product;
      } else {
        return null;
      }
    },
    customProductError() {
      return this.customProductFormData?.error;
    },
  },
  created() {
    if (this.roomProduct) {
      let product = this.relatedProducts.find((relatedProduct) => {
        return (
          relatedProduct.service_product_id ===
            this.roomProduct.service_product_id ||
          relatedProduct.service_product_id === this.roomProduct.id
        );
      });

      if (product) {
        this.selected = {
          ...product,
          name: product?.name || product.product_id,
        };
      } else if (this.customProduct && this.customProduct.id) {
        this.selected = {
          service_product_id: "default",
          name: "Mon propre article",
        };
      } else {
        this.selected = {
          service_product_id: "empty",
          name: "Sélectionnez la fourniture",
        };
      }
      if (!this.selected?.product_id) return;
      this.loader = true;
      Promise.all([
        this.getProduct(this.selected?.product_id),
        this.getPrice(this.selected?.service_combination_id),
        this.getPricesRange(this.selected?.service_product_id),
      ])
        .then(() => {
          this.pricePerUnit = this.getPricePerUnit();
          this.priceTotal = this.getPriceTotal();
        })
        .finally(() => (this.loader = false));
    }
  },
  methods: {
    getPricePerUnit() {
      if (this.serviceTax && this.serviceTax > 0) {
        return (
          this.productInfo.price +
          this.productInfo.price * (this.serviceTax / 100)
        );
      }
      return this.productInfo.price;
    },
    getPriceTotal() {
      if (this.serviceTax && this.serviceTax > 0) {
        return (
          (this.productInfo.price +
            this.productInfo.price * (this.serviceTax / 100)) *
          this.amount
        );
      }
      return this.productInfo.price * this.amount;
    },
    onChange(payload) {
      let { service_product_id, product_id, service_combination_id } = payload;
      if (
        service_product_id === "default" &&
        !this.roomProduct?.custom_product
      ) {
        this.$emit("selectedProduct", {
          id: service_product_id,
          product: null,
          productPrice: 0,
        });
        this.showModal = true;
        return false;
      }

      if (
        service_product_id === "default" &&
        this.roomProduct?.custom_product
      ) {
        this.$emit("selectedProduct", {
          id: service_product_id,
          product: null,
          productPrice: 0,
        });
        return false;
      }

      if (service_product_id === "empty") {
        this.$emit("selectedProduct", {
          id: service_product_id,
          product: null,
          productPrice: 0,
        });
        return false;
      }

      if (typeof service_product_id === "number") {
        this.loader = true;
        Promise.all([
          this.getProduct(product_id),
          this.getPrice(service_combination_id),
          this.getPricesRange(service_product_id),
        ])
          .then(([product]) => {
            this.pricePerUnit = this.getPricePerUnit();
            this.priceTotal = this.getPriceTotal();
            this.$emit("selectedProduct", {
              id: service_product_id,
              product,
              productPrice: this.pricePerUnit,
            });
            this.$emit("updateRange", this.pricesRange);
          })
          .finally(() => (this.loader = false));
      }

      return false;
    },
    getPrice(id) {
      if (this.selected.service_product_id === "default") return;
      return ServiceProductsService.getProducts(id)
        .then(({ data }) => (this.prices = data))
        .catch((ex) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", ex);
        });
    },
    getPricesRange(id) {
      if (this.selected.service_product_id === "default") return;
      return ServiceProductPriceService.getProductPrices(id)
        .then(({ data }) => {
          this.pricesRange = data;
          this.$emit("updateRange", data);
        })
        .catch((ex) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", ex);
        });
    },
    getProduct(id) {
      if (this.selected.service_product_id === "default" && !id) return;
      return ProductsService.getProductById(id)
        .then(({ data }) => (this.productInfo = data))
        .catch((ex) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", ex);
        });
    },
    actionCustomProduct(payload) {
      if (!payload) return;
      this.$emit("changeCustomProduct", payload);
    },
  },
};
</script>

<style src="./ProductsOfService.scss" lang="scss" scoped></style>