var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"related-products"},[_c('div',{staticClass:"related-products__select"},[_c('v-select',{attrs:{"outlined":"","label":"Choisir la gamme de fourniture","items":_vm.productsList,"item-text":"name","item-value":"product_id","persistent-placeholder":"","dense":"","hide-details":"","return-object":"","error":(_vm.customProductError && _vm.selected.service_product_id === 'default') ||
        _vm.selected.service_product_id === 'empty'},on:{"change":_vm.onChange},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(
      _vm.selected.service_product_id !== 'default' &&
      _vm.selected.service_product_id !== 'empty' &&
      _vm.productInfo
    )?_c('ProductInfo',{attrs:{"loader":_vm.loader,"image":_vm.styleItem(_vm.productInfo.image),"description":_vm.productInfo.description || '',"pricePerUnit":_vm.amount
        ? _vm.currentProductPriceWithTaxByUnit
        : _vm.pricePerUnit !== null
        ? _vm.pricePerUnit
        : 0,"priceTotal":_vm.currentProductPriceWithTaxByUnit * _vm.amount,"serviceUnit":_vm.serviceUnit}}):_vm._e(),(_vm.selected.service_product_id === 'default' && _vm.customProduct)?_c('CustomProductInfo',{attrs:{"loader":_vm.loader,"name":_vm.customProduct.name,"mark":_vm.customProduct.mark,"reference":_vm.customProduct.reference || '',"description":_vm.customProduct.description || '',"customPrice":(_vm.customProduct.client_price || 0).toFixed(2) + ' €'}}):_vm._e(),_c('div',{staticClass:"related-products__button"},[(_vm.selected.service_product_id === 'default')?_c('v-btn',{attrs:{"outlined":"","color":!_vm.customProductError ? 'primary' : 'error',"depressed":"","x-large":""},on:{"click":function($event){_vm.showModal = true}}},[(!_vm.customProduct)?[_c('v-icon',{staticClass:"mx-2",attrs:{"dark":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Informations sur mon article ")]:_vm._e(),(_vm.customProduct)?[_c('v-icon',{staticClass:"mx-2",attrs:{"dark":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editer mon article ")]:_vm._e()],2):_vm._e(),(_vm.selected.service_product_id === 'default' && _vm.customProductError)?_c('div',{staticClass:"error--text text-center"},[_c('small',[_vm._v("Requis")])]):_vm._e()],1),_c('ArticleOfProductModal',{attrs:{"show":_vm.showModal,"model":_vm.customProduct},on:{"updateCustomProduct":function($event){return _vm.$emit('updateCustomProduct')},"closeModal":function($event){_vm.showModal = false},"actionCustomProduct":_vm.actionCustomProduct}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }